import React, { useMemo, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Alert, Button, Col, List, Row, Skeleton, Typography } from "antd"
import { useTranslation } from "react-i18next"
import { formatDate } from "../utils"
import useNotificationAction from "../hooks/useNotificationAction"

const { Paragraph, Text } = Typography

const NOTIFICATION_FEEDS_QUERY = gql`
  query notificationFeeds($limit: Int = 10, $channel: jsonb) {
    notification_feeds(
      order_by: { created_at: desc }
      limit: $limit
      where: { notification: { channels: { _contains: $channel } } }
      distinct_on: created_at
    ) {
      read_at
      notification {
        id
        title
        subtitle
        content
        created_at
        data
      }
    }
  }
`

const UPDATE_READ_AT_MUTATIONE = gql`
  mutation updateNotificationFeed($id: Int, $now: timestamptz) {
    update_notification_feeds(
      where: { notification_id: { _eq: $id } }
      _set: { read_at: $now }
    ) {
      affected_rows
    }
  }
`

const pageSize = 10

const NotificationFeeds = () => {
  const [limit, setLimit] = useState(pageSize)
  const { t } = useTranslation()
  const { handleNotificationOpen } = useNotificationAction()

  const { data, loading, error, refetch } = useQuery(NOTIFICATION_FEEDS_QUERY, {
    variables: {
      limit,
      channel: "anyWeb",
    },
  })

  const [updateNotificationFeed] = useMutation(UPDATE_READ_AT_MUTATIONE)

  const loadMore = useMemo(() => {
    if (loading || error) {
      return null
    }

    if (limit > data.notification_feeds.length) {
      return null
    }

    return (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button
          disabled={loading}
          onClick={() => {
            setLimit(limit + pageSize)
          }}
        >
          {t("button:loadMore")}
        </Button>
      </div>
    )
  }, [data, limit, loading, error])

  if (loading) {
    return <Skeleton active round />
  }

  if (error) {
    return <Alert description={error.message} type="error" showIcon />
  }

  return (
    <List
      locale={{ emptyText: t("label:emptyNotificationFeeds") }}
      className="notification-feed-list"
      loading={loading}
      itemLayout="vertical"
      loadMore={loadMore}
      dataSource={data.notification_feeds.map(({ notification, read_at }) => ({
        ...notification,
        read_at,
      }))}
      renderItem={(
        { id, title, subtitle, content, created_at, data },
        index
      ) => (
        <List.Item
          actions={
            data
              ? [
                  <Button
                    type="primary"
                    className="yellowsp"
                    onClick={async () => {
                      await handleNotificationOpen({ id, title, data })
                    }}
                  >
                    {t("button:accessNow")}
                  </Button>,
                ]
              : []
          }
        >
          <List.Item.Meta
            // avatar={<Avatar size={58} icon={<NotificationFilled />} />}
            title={
              <Row justify="space-between">
                <Col>
                  <h2>{title}</h2>
                </Col>
                <Col>{formatDate(created_at)}</Col>
              </Row>
            }
            description={subtitle}
          />
          {content}
        </List.Item>
      )}
    />
  )
}

export default NotificationFeeds

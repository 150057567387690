import React from "react"
import { Col, Layout as AntLayout, Row } from "antd"
import logo from "../../static/assets/images/logo-completo.svg"
import "./MainFooter.less"
import { Link } from "gatsby"
import { translatePath } from "../utils"
import i18n from "i18next"
import { useTranslation } from "react-i18next"
import { SUBSCRIPTION_DISABLED } from "../config"

const footerColSettings = {
  xs: 24,
  md: 6,
  lg: 4,
}

const footerColSettingsLogo = {
  xs: 24,
  md: 24,
  lg: 8,
}

const footerColSettingsData = {
  xs: 12,
  md: 6,
  lg: 4,
}

const { Footer } = AntLayout

const profileMenuList = [
  {
    key: "/me/profile",
    name: i18n.t("menu:settings"),
    description: i18n.t("menu:settingsDescription"),
  },
  {
    key: "/help",
    name: i18n.t("menu:helpDesk"),
    description: i18n.t("menu:helpDeskDescription"),
  },
  {
    key: "/me/billing/payment-method/list",
    name: i18n.t("menu:billing"),
    description: i18n.t("menu:billingDescription"),
  },
  {
    key: "/me/billing",
    name: i18n.t("menu:billingHistory"),
    description: i18n.t("menu:billingHistoryDescription"),
  },
]

if (!SUBSCRIPTION_DISABLED) {
  profileMenuList.push({
    key: "/me/subscription",
    name: i18n.t("menu:subscription"),
    description: i18n.t("menu:subscriptionDescription"),
  })
}

const MainFooter = () => {
  const { t } = useTranslation()
  return (
    <Footer className={"main-footer"}>
      <Row className="footer-wrapper">
        <Col {...footerColSettingsLogo} className="container-footer-logo">
          <img src={logo} alt="logo" className="footer-logo" />
          <p className="footer-description">
            {t("message:footerDescription1")}
          </p>
        </Col>
        <Col {...footerColSettingsData} className="each-col">
          <h1 className={"header"}>{t("footer:navigation")}</h1>
          <ul>
            <li>
              <Link to={"/"}>{t("menu:home")}</Link>
            </li>
            <li>
              <Link to={translatePath("/on-demand")}>{t("menu:onDemand")}</Link>
            </li>
            <li>
              <Link to={translatePath("/category/all")}>
                {t("menu:categories")}
              </Link>
            </li>
            <li>
              <Link to={translatePath("/channel/all")}>
                {t("menu:channels")}
              </Link>
            </li>
            <li>
              <Link to={translatePath("/author/all")}>{t("menu:authors")}</Link>
            </li>
            <li>
              <Link to={translatePath("/me/library")}>{t("menu:library")}</Link>
            </li>
          </ul>
        </Col>
        <Col {...footerColSettingsData} className="each-col">
          <h1 className={"header"}>Account</h1>
          <ul>
            {profileMenuList.map(({ key, name }) => (
              <li key={key}>
                <Link to={translatePath(key)}>{name}</Link>
              </li>
            ))}
          </ul>
        </Col>
        <Col {...footerColSettingsData} className="each-col">
          <h1 className={"header"}>{t("footer:about")}</h1>
          <ul>
            <li>
              <Link to={translatePath("/tos")}>{t("footer:tos")}</Link>
            </li>
            <li>
              <Link to={translatePath("/privacy")}>{t("footer:privacy")}</Link>
            </li>
            <li>
              <Link to={translatePath("/cookies")}>{t("footer:cookies")}</Link>
            </li>
            <li>
              <Link to={translatePath("/about")}>{t("footer:about")}</Link>
            </li>
          </ul>
        </Col>
        <Col {...footerColSettingsData} className="each-col">
          <h1 className={"header"}>Social</h1>
          <ul>
            <li>
              <Link to={"https://twitter.com/Sportsciencecom"} target="_blank">
                Twitter
              </Link>
            </li>
            <li>
              <Link
                to={"https://www.facebook.com/sportscienceIT"}
                target="_blank"
              >
                Facebook
              </Link>
            </li>
            <li>
              <Link
                to={"https://www.youtube.com/channel/UCtuKM0C93fcp35qqlz_9XQQ"}
                target="_blank"
              >
                Youtube
              </Link>
            </li>
            <li>
              <Link
                to={"https://www.instagram.com/sportscienceitalia/"}
                target="_blank"
              >
                Instagram
              </Link>
            </li>
            <li>
              <Link
                to={"https://www.linkedin.com/company/76713497"}
                target="_blank"
              >
                Linkedin
              </Link>
            </li>
          </ul>
        </Col>
        <Col span={24} className="each-col-bottom">
          <h5>{t("message:footerDescription2")}</h5>
          <h5>{t("message:footerDescription3")}</h5>
        </Col>
      </Row>
    </Footer>
  )
}

export default MainFooter

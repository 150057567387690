import { translatePath } from "../utils"
import { navigate } from "gatsby"

const useNotificationAction = () => {
  const createSectionNavigationOptions = section => {
    const allScreens = {
      HOME_PAGE: "/",
      ON_DEMAND: translatePath("/on-demand"),
      CATEGORIES: translatePath("/category/all"),
      CERTIFICATIONS: translatePath("/certification/all"),
      CHANNELS: translatePath("/channel/all"),
      AUTHORS: translatePath("/author/all"),
      LIBRARY: translatePath("/me/library"),
      WEBINAR: translatePath("/webinar"),
      HUMAN_3D: translatePath("/human"),
      MY_CERTIFICATIONS: translatePath("/me/certification"),
      SPECIAL_PRICES: translatePath("/special-price"),
      GIFT_CARDS: translatePath("/gift-card"),
      BUNDLES: translatePath("/bundle"),
    }

    return allScreens[section] ?? section
  }

  const handleNotificationOpen = notification => {
    const data = notification.additionalData || notification.data || {}
    const { action, value } = data

    let navigationOptions = null
    switch (action) {
      case "item":
        // value in format => typename:id
        const [contentType, id] = value.split(":")
        navigate(`/go?id=${id}`)
        break
      case "webPage":
        // value is url
        window.open(value, "_blank")
        break
      case "section":
        // value is a key to section
        navigationOptions = createSectionNavigationOptions(value)
        navigate(navigationOptions)
        break
      default:
        break
    }
  }

  return {
    handleNotificationOpen,
  }
}

export default useNotificationAction
